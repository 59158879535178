<template>
  <div class="spot trading-board bg-primary1">
    <SimpleHeader></SimpleHeader>
    <TradingLayout>
      <div slot="trading" class="trade-box flex1">
        <el-tabs>
          <el-tab-pane :label="$t(301)">
            <div class="flex align-center">
              <el-form
                ref="form"
                :rules="rules"
                :model="form"
                class="flex1 form"
              >
                <div v-if="!showCommonSuccess">
                  <div class="pro-msg flex">
                    <div class="flex align-center flex1"><span class="label">{{ $t(266) }}</span><span class="title">{{ curCoinName }}</span></div>
                    <div class="flex align-center flex1">
                      <span class="label">{{ $t(250) }}</span>
                      <span class="price" :style="{color: coinPriceMap[curCoinName+'USDT'].change>=0?riseColor: fallColor}">
                        {{ coinPriceMap[curCoinName+'USDT'].price }}</span> USDT
                    </div>
                  </div>
                  <div class="direction">
                    <span class="rise" :class="{active: form.direction===1, colorMode1: colorType === 'colorMode1',  colorMode2: colorType === 'colorMode2'}" @click="form.direction=1">{{ $t(288) }}</span>
                    <span class="down" :class="{active: form.direction===2, colorMode1: colorType === 'colorMode1',  colorMode2: colorType === 'colorMode2'}" @click="form.direction=2">{{ $t(289) }}</span>
                  </div>
                  <el-form-item
                    prop="amount"
                    class="amount"
                  >
                    <el-input v-model="form.amount"></el-input>
                    <span class="label">{{ form.direction===1?$t(268) :$t(302)}}</span>
                  </el-form-item>
                  <div class="block">
                    <el-slider
                      v-model="form.amount"
                      :step="step"
                      :marks="marks"
                      :max="form.direction===1?+usdtBalance: +coinBalance"
                      show-stops>
                    </el-slider>
                  </div>
                  <div class="flex balance">
                    <span v-if="form.direction===1">{{ $t(193) }}: {{ usdtBalance }}USDT <span class="el-icon-sort cursor primary"  @click="$router.push({name: 'Transfer', query:{to: 'SPOT', from:'FUTURE'}})">{{ $t(188) }}</span></span>
                    <span v-else >{{ $t(193) }}: {{ coinBalance }}{{ curCoinName }} <span class="el-icon-sort cursor primary"  @click="$router.push({name: 'Transfer', query:{to: 'SPOT', from:'FUTURE'}})">{{ $t(188) }}</span></span>
                    <span v-if="!!+configObj.spotTradingFee">{{ $t(270) }}:{{ configObj.spotTradingFee }}%</span>
                  </div>
                </div>
                <div v-else class="success">
                  <p>
                    <i class="el-icon-finished"></i>
                    <span>{{ $t(275) }}</span>
                  </p>
                </div>
                <el-form-item>
                  <el-button v-if="userLogined" type="primary" :disabled="loading" :loading="loading" @click="confirmSubmit">{{ showCommonSuccess ? $t(280):$t(284) }}</el-button>
                  <el-button v-else type="primary" @click="$router.push({name: 'Login', query:{redirect: $route.name}})">
                    <span type="text">{{ $t(94) }}</span>
                    <span class="color-normal"> / </span>
                    <span type="text">{{ $t(95) }}</span>
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </TradingLayout>
    <Footer></Footer>
  </div>
</template>

<script>
import TradingLayout from './components/TradingLayout'
import tradingMixin from '@/mixins/tradingMixin'
import { buySpot,sellSpot,  getFutureOrderDetail } from '@/api'
import SimpleHeader from '@/components/SimpleHeader'
import Footer from '@/components/footer/Footer'
import utils from '@/utils/misc'
export default {
  name: 'Spot',
  components: {
    TradingLayout,
    SimpleHeader,
    Footer
  },
  mixins: [tradingMixin],
  data() {
    return {
      curCate: 'SPOT',
      form: {
        amount: 0,
        direction: 1,
        tradingId: ''
      },
      
      showCommonSuccess: false,
      loading: false,
    }
  },
  computed: {
    rules(){
       return {
          amount: [
          { required: true, message: this.form.direction===1?this.$t(269):this.$t(303) },
          // { trigger: 'blur', validator: (rule, value, callback) => {
          //   if (isNaN(value) || value < 0) {
          //     return callback(new Error(this.$t(210)))
          //   }
          //   if (this.contract.minAmount && value < +this.contract.minAmount) {
          //     return callback(new Error(this.$t(271) + this.contract.minAmount))
          //   }
          //   if (+value > +this.curAssets.availableAmount) {
          //     return callback(new Error(this.$t(235)))
          //   }
          //   callback()
          // } }
        ]
       }
    },
    userLogined() {
      return utils.getLs('token')
    },
    configObj() {
      return window.configObj
    },
    usdtBalance() {
      let balance = 0
      if (this.curAssets.coins instanceof Array) {
        for (const item of this.curAssets.coins) {
          if (item.coin === 'USDT') {
            balance = item.availableAmount
            break
          }
        }
      }
      return balance
    },
    coinBalance() {
      let balance = 0
      if (this.curAssets.coins instanceof Array) {
        for (const item of this.curAssets.coins) {
          if (item.coin === this.curCoinName) {
            balance = item.availableAmount
            break
          }
        }
      }
      return balance
    },
    step() {
      const total = this.form.direction === 1 ? this.usdtBalance : this.coinBalance
      return (total / 8) || 1
    },
    marks() {
      const total = this.form.direction === 1 ? +this.usdtBalance : +this.coinBalance
      return total ? {
        0: '0%',
        [this.step*1]: '12.5%',
        [this.step*2]: '25%',
        [this.step*3]: '37.5%',
        [this.step*4]: '50%',
        [this.step*5]: '62.5%',
        [this.step*6]: '75%',
        [this.step*7]: '87.5%',
        [this.step*8]: '100%',
      }: {}
    }
  },
  watch: {
  },
  beforeMount() {
  },
  mounted() {
  },
  methods: {
    confirmSubmit() {
      if (this.showCommonSuccess) {
        this.showCommonSuccess = false
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const api = this.form.direction === 1 ? buySpot : sellSpot
          api({
            amount: +this.form.amount,
            pair: `${this.curCoinName}USDT`,
          }).then(res => {
            this.$store.dispatch('getUserAssets')
            utils.emit('updateList')
            this.showCommonSuccess = true
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.trading-board{
  .block{
    padding-left: 10px;
  }
  .pro-msg{
    padding: 10px 0px;
    .label{
      font-size: 12px;
      margin-right: 20px;
    }
    .title,.price{
      font-size: 16px;
      font-weight: 800;
    }
    .flex:not(:first-child){
      margin-left: 20px;
    }
  }
  .form{
    padding: 0px 20px;
    .balance{
      font-size: 12px;
      margin: 20px 0px 18px 0px;
      span{
        &:nth-last-child(1){
          flex: 1;
          text-align: right;
        }
      }
    }
    .success{
      height: 200px;
      font-size: 20px;
      text-align: center;
      line-height: 200px
    }
    .success-modle{
      p{
        line-height: 28px;
        .value{
          font-size: 12px;
          font-weight: normal;
        }
      }
      .p-wrap{
        margin: 10px;
        position: relative;
        .left-time{
          position: absolute;
          color: #333;
          font-weight: 700;
          font-size: 15px;
          left: 51px;
          top: 71px;
          display: inline-block;
          width: 60px;
          text-align: center;
        }
        .el-progress{
          height: 180px;
          width: 180px;
        }
      }
    }
    .el-form-item{
      margin-bottom: 4px;
    }
    .amount{
      position: relative;
      span{
        position: absolute;
        // color: #fff;
      }
      .label{
        left: 10px;
        top: 0px;
        color: #666;
      }
      .max{
        right: 10px;
        top: 0px;
        cursor: pointer;
        &:hover{
          opacity: .8;
        }
      }
      .el-input__inner{
        padding-left: 140px;
      }
    }
  }
  .direction{
    display: flex;
    span{
      display: inline-block;
      flex:1;
      cursor: pointer;
      border-radius: 3px;
      &:not(:first-child){
        margin-left: 20px;
      }
      padding: 8px;
      text-align: center;
      border: 1px solid $borderColor;
      color: #999;
      &.active.rise.colorMode2, &.active.down.colorMode1{
        border-color: #00d3ad;
        color: #00d3ad;
        font-weight: 800;
      }
      &.active.down.colorMode2, &.active.rise.colorMode1{
        border-color: #fa4d56;
        color: #fa4d56;
        font-weight: 800;
      }
    }
    margin: 10px 0px 20px 0px;
  }
  .el-tabs__header{
    padding: 4px;
  }
  .el-tabs__nav{
    padding-left: 10px;
  }
  .el-tabs__header{
    margin-bottom: 0px;
  }
  .el-tabs__header .el-tabs__active-bar{
    left: 10px;
  }
}
</style>
